import React, {useContext, useRef, useState} from 'react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';

import ChannelsHero from '../components/channels/ChannelsHero';
import ChannelsList from '../components/channels/ChannelsList';
import Pagination from '../components/common/Pagination';
import {EndOfPageCTA} from '../components/common/Clickables';
import SEO from '../components/common/SEO';
import {colors, ContentContainer} from '../styles/theme';
import getCustomizedFilterSchema from '../helpers/channels';
import {H2, H5Mono} from '../components/common/Typography';
import useFilters from '../helpers/hooks/useFilters';
import usePagination from '../helpers/hooks/usePagination';
import useFiltersFromQueryParameters from '../helpers/hooks/useFiltersFromQueryParameters';
import {ContrastContext} from '../helpers/context';


/*
 * Constants
 */
const elementsPerPage = 10;


/*
 * Private Elements
 */

// Channels
const ChannelsContentContainer = styled.div`
    background-color: ${colors.lightGrey};
    padding: 0 20px 60px;

    @media (min-width: 885px) {
        padding: 0 60px 70px;
    }

    ${ContentContainer} {
        @media (max-width: 885px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

// Sales CTA
const ContactSalesCTAContentContainer = styled.div`
    background-color: ${colors.lightGrey};
`;


/*
 * Public Elements
 */
const ChannelsPage = ({data, location}) => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();
    const {navigate} = useI18next();

    // Get the Channels data
    const normalizedChannels = data.allStrapiChannel.edges.map(channel => channel.node);

    // Get filterable data from Channels filters schema and Channels data
    const customizedSchema = getCustomizedFilterSchema(normalizedChannels);

    // Get the list of allowed filters for Channels
    const allowedChannelsFilters = Object.keys(customizedSchema);

    // Get query params
    const [queryParamsFilters, setQueryParams] = useFiltersFromQueryParameters(location, allowedChannelsFilters);

    // Set the selected filters and current page
    const [selectedFilters, setSelectedFilters] = useState(queryParamsFilters);
    const [currentPage, setCurrentPage] = useState(1);

    // Filter and paginate Channels
    const filteredChannels = useFilters(normalizedChannels, selectedFilters, customizedSchema);
    const paginatedChannels = usePagination(filteredChannels, currentPage, elementsPerPage);

    // Set the defined filters/page in the URL
    if (currentPage === 1) {
        setQueryParams({...selectedFilters});
    } else {
        setQueryParams({...selectedFilters, currentPage});
    }

    const setFilters = filters => {
        const f = {};
        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                f[key] = value;
            }
        });
        setSelectedFilters(f);

        // Changing filters MUST also change the pagination to the first page
        setCurrentPage(1);
    };

    const channelsPaginationTopRef = useRef();

    return (
        <>
            <SEO
                title={t('Channels')}
                ogTitle={t('Work with Multiple Payment Providers Around the Globe - Switch')}
                description={t('Connect with multiple payment gateways, acquirers, payment services providers through '
                    + 'a single integration. More than 70 payment methods available worldwide.')}
                image="/website/metatags/channels.png"
            />

            <ChannelsHero filters={selectedFilters} setFilters={setFilters} filtersSchema={customizedSchema} />

            <ChannelsContentContainer ref={channelsPaginationTopRef} id="list">
                <ContentContainer>
                    <Pagination
                        numberOfElements={filteredChannels.length}
                        elementsPerPage={elementsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />

                    <ChannelsList
                        channels={paginatedChannels}
                        channelClick={channelId => navigate(
                            `/channels/${channelId}`,
                            {state: {selectedFilters, scrollToId: 'list'}},
                        )}
                    />

                    <Pagination
                        numberOfElements={filteredChannels.length}
                        elementsPerPage={elementsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        scrollToRef={channelsPaginationTopRef}
                    />
                </ContentContainer>
            </ChannelsContentContainer>

            <ContactSalesCTAContentContainer>
                <EndOfPageCTA link="/about/contact-sales" linkText={t('Contact Sales')}>
                    <H2><Trans>Did you find what you&apos;re looking for?</Trans></H2>
                    <H5Mono><Trans>Our team is here to help you integrate.</Trans></H5Mono>
                </EndOfPageCTA>
            </ContactSalesCTAContentContainer>
        </>
    );
};

ChannelsPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default ChannelsPage;
export const query = graphql`
    query($language: String!) {
        allStrapiChannel(filter: {language: {eq: $language}}) {
            edges {
                node {
                    id: strapiId
                    status
                    region
                    language
                    payment_provider {
                        name
                        type
                        logo {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: TRACED_SVG
                                        width: 100
                                    )
                                }
                            }
                        }
                    }
                    payment_method {
                        name
                        type
                        logo {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: TRACED_SVG
                                        width: 100
                                    )
                                }
                            }
                        }
                        countries {
                            name: name_slug
                            region
                        }
                    }
                }
            }
        }
    }
`;
